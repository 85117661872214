import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Hero.css";

const Hero = ({ heroData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % heroData.length);
    }, 7000);
    return () => clearInterval(interval);
  }, [heroData.length]);

  return (
    <motion.div
      className="hero-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {heroData.map((hero, index) => (
        <div
          key={index}
          className={`hero-slide ${index === currentSlide ? "active" : ""}`}
        >
          {hero.video ? (
            <video autoPlay muted loop playsInline className="hero-video">
              <source src={hero.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={hero.image}
              alt={`Slide ${index + 1}`}
              className="hero-image"
            />
          )}
          <div className="hero-overlay">
            <motion.h2
              className="hero-subheader"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              {hero.subHeader}
            </motion.h2>
            <motion.h1
              className="hero-header"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.7, duration: 1 }}
            >
              {hero.header}
            </motion.h1>
            <motion.p
              className="hero-paragraph"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.9, duration: 1 }}
            >
              {hero.paragraph}
            </motion.p>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

export default Hero;
