import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import Header from "../../pages/Header";

const RegistrationForm = () => {
  const { handleCompanyRegister } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();

  const initialState = {
    companyName: "",
    username: "",
    password: "",
    confirmPassword: "",
    companyAddress: "",
    companyEmail: "",
    phoneNumber: "",
    companyType: "", 
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await handleCompanyRegister(formData);
      enqueueSnackbar("Registration successful!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Registration failed. Please try again.", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  return (
    <div>

   
    <Header title="Registration" />
    <motion.div
      className="registration-container"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ type: "spring", duration: 0.5 }}
    >
      <h2>Company Registration</h2>
      <form onSubmit={handleSubmit} className="registration-form">
        <div className="registration-input">
          <div className="register-form-group-top">
            {" "}
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="register-form-group-top">
            {" "}
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="register-form-group">
          {" "}
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="register-form-group">
          {" "}
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>

        <div className="register-form-group">
          {" "}
          <label htmlFor="companyAddress">Company Address</label>
          <input
            type="text"
            id="companyAddress"
            name="companyAddress"
            value={formData.companyAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div className="register-form-group">
          {" "}
          <label htmlFor="companyEmail">Company Email</label>
          <input
            type="email"
            id="companyEmail"
            name="companyEmail"
            value={formData.companyEmail}
            onChange={handleChange}
            required
          />
        </div>

        <div className="register-form-group">
          {" "}
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="register-form-group-bottom">
          <label htmlFor="companyType">Company Type</label>
          <select
          className="select"
            id="companyType"
            name="companyType"
            value={formData.companyType}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="Shipping">Shipping</option>
            <option value="Delivery">Delivery</option>
          </select>
        </div>
        <div className="form-group-btn">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={loading}
            className="submit-btn"
          >
            {loading ? "Registering…" : "Register"}
          </motion.button>
        </div>
      </form>
    </motion.div>
     </div>
  );
};

export default RegistrationForm;
