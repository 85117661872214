import React from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import ReactPlayer from "react-player/lazy";
import Clients from "../components/Clients";
import "./About.css";

const About = () => {
  return (
    <div>
      <Header title="About Us" />
      <motion.div
        className="about-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <section className="about-content-section">
          <motion.div
            className="about-content"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2>Who We Are</h2>
            <p>
              <strong>The Wright Logistics LLC</strong> is a family-owned
              logistics company established in 2021. Our mission is to provide
              safe, reliable, and expedited delivery services tailored to your
              business needs.
            </p>
            <ul>
              <li>
                <strong>Founded:</strong> 2021
              </li>
              <li>
                <strong>Ownership:</strong> Privately Owned
              </li>
              <li>
                <strong>Core Focus:</strong> Building partnerships and
                delivering exceptional logistics services.
              </li>
            </ul>
          </motion.div>
          <motion.div
            className="about-video-container"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <ReactPlayer
              url="https://youtu.be/3_-YVqrnUGE"
              className="react-player"
              playing
              controls
              width="100%"
              height="100%"
            />
          </motion.div>
        </section>
      </motion.div>
      <Clients />
    </div>
  );
};

export default About;
