import axiosInstance from "../axiosInstance";

const validateUserToken = async () => {
  try {
    const response = await axiosInstance.get("/auth/validate-token");
    return response.data.valid;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

const registerCompany = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/register", formData);
    console.log(response);

    return response.data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw new Error(error.response?.data?.error || "Registration failed.");
  }
};

const login = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/login", formData);
    return { token: response.data.token, company: response.data.company };
  } catch (error) {
    console.error("Login attempt failed:", error);
    throw new Error(error.response?.data?.error || "Login failed.");
  }
};



export {
  login,
  registerCompany,
  validateUserToken,
};
