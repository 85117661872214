const TOKEN_KEY = "token";
const COMPANY_KEY = "company";
const REFRESH_TOKEN_KEY = "refreshToken";

export const setStoredToken = (token) => {
  if (token) localStorage.setItem(TOKEN_KEY, token);
};

export const getStoredToken = () => localStorage.getItem(TOKEN_KEY);

export const removeStoredToken = () => localStorage.removeItem(TOKEN_KEY);

export const setStoredCompany = (company) => {
  if (company && typeof company === "object") {
    localStorage.setItem(COMPANY_KEY, JSON.stringify(company));
  }
};

export const getStoredCompany = () => {
  try {
    const storedCompany = localStorage.getItem(COMPANY_KEY);
    return storedCompany ? JSON.parse(storedCompany) : null;
  } catch {
    return null;
  }
};

export const removeStoredCompany = () => localStorage.removeItem(COMPANY_KEY);

export const setStoredRefreshToken = (refreshToken) => {
  if (refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const getStoredRefreshToken = () =>
  localStorage.getItem(REFRESH_TOKEN_KEY);

export const removeStoredRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_KEY);
