import React from "react";
import { motion } from "framer-motion";
import Clients from "../components/Clients";
import Contact from "../components/LandingContactForm";
import Hero from "../components/Hero";
import HomeAbout from "../components/HomeAbout";
import HomeServices from "../components/HomeServices";
import Carousel from "../components/Carousel";
import HeroVideo from "../components/Images/pexels_videos_3409 (1080p).mp4";
import "./Home.css";

const images = [
  "https://images.unsplash.com/photo-1592805144716-feeccccef5ac?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  "https://images.unsplash.com/photo-1561702469-c4239ced3f47?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
];

const heroVideoData = [
  {
    video: HeroVideo,
    header: "Safe, Reliable, and Expedited Delivery",
    subHeader: "Same-Day Shipping Across California",
    paragraph:
      "Pickup by 8 AM for guaranteed same-day delivery. Experience live shipment negotiation for the best prices with our in-house load board.",
  },
];


// Animation variants
const sectionVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

const Home = () => (
  <div>
    <Hero heroData={heroVideoData} />
    <motion.div
      className="home-content"
      initial="hidden"
      animate="visible"
      variants={sectionVariants}
    >
      <motion.section variants={sectionVariants}>
        <HomeAbout />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <HomeServices />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Clients />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Carousel images={images} />
      </motion.section>
      <motion.section variants={sectionVariants}>
        <Contact />
      </motion.section>
    </motion.div>
  </div>
);

export default Home;
