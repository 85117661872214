import React, { useState } from "react";
import "./contact.css";
import Header from "./Header";
import img1 from "../components/Images/contactperson.png";
import Mapembeded from "../components/Mapembeded";
import InlineError from "../components/validationFolder/InlineError";
import {
  validateEmail,
  validateName,
  validateMessage,
  validatePhone,
  validateSubject,
} from "../components/validationFolder/Validation";
import { motion } from "framer-motion";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [messageSent, setMessageSent] = useState(false);

  const validateFields = () => {
    const newErrors = {
      fullName: validateName(formData.fullName),
      phone: validatePhone(formData.phone),
      email: validateEmail(formData.email),
      subject: validateSubject(formData.subject),
      message: validateMessage(formData.message),
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      await axios.post("/contact", formData);
      setMessageSent(true);
      setFormData({
        fullName: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
      });
      setErrors({});
    } catch (error) {
      console.error("Error sending email:", error);
      setMessageSent(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="contact">
      <Header title="Contact" />
      <motion.div
        className="contact-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="contact-wrapper">
          <motion.form
            id="contact-form"
            onSubmit={handleSubmit}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div className="form-header">
              <motion.h2
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                Get In Touch
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                We'd love to hear from you. Please fill out the form below to
                reach out to us.
              </motion.p>
            </div>
            <div className="contact-form-groups">
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  required
                  onChange={handleChange}
                  placeholder="Full Name"
                />
                {errors.fullName && <InlineError error={errors.fullName} />}
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  required
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
                {errors.phone && <InlineError error={errors.phone} />}
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                />
                {errors.email && <InlineError error={errors.email} />}
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                />
                {errors.subject && <InlineError error={errors.subject} />}
              </div>
              <div className="form-group">
                <textarea
                  className="form-textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                />
                {errors.message && <InlineError error={errors.message} />}
              </div>
              {messageSent && (
                <motion.div
                  className="msg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4 }}
                >
                  Message has been sent!
                </motion.div>
              )}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="submit-btn"
                type="submit"
              >
                Submit
              </motion.button>
            </div>
          </motion.form>
          <motion.div
            className="contact-img"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.6 }}
          >
            <img src={img1} alt="Contact Person" />
          </motion.div>
        </div>
      </motion.div>
      <motion.div
        className="map-container"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, ease: "easeOut" }}
      >
        <Mapembeded />
      </motion.div>
    </div>
  );
};

export default Contact;
