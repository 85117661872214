import React, {
  createContext,
  useReducer,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getStoredToken,
  setStoredToken,
  removeStoredToken,
  getStoredCompany,
  setStoredCompany,
  removeStoredCompany,
} from "../LocalStorageManager/LocalStorageManager";
import { login, validateUserToken, registerCompany } from "../authAPI/AuthAPI";
import axiosInstance from "../axiosInstance";

const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
};

const initialState = {
  company: getStoredCompany(),
  loading: true,
  error: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        company: action.payload.company,
        loading: false,
        error: null,
      };
    case ActionTypes.LOGOUT:
      return { ...initialState, loading: false };
    case ActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case ActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();


  const clearAuthData = useCallback(() => {
    removeStoredToken();
    removeStoredCompany();
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = getStoredToken();
      const storedCompany = getStoredCompany();

      if (token && storedCompany) {
        dispatch({ type: ActionTypes.SET_LOADING, payload: true });

        const isValid = await validateUserToken(token);
        if (isValid) {
          dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: storedCompany });
        } else {
          clearAuthData();
          dispatch({ type: ActionTypes.LOGOUT });
          navigate("/login", { replace: true });
        }
      } else {
        clearAuthData();
        dispatch({ type: ActionTypes.LOGOUT });
      }
    };
    initializeAuth();
  }, [clearAuthData, navigate]);

  const handleLogin = useCallback(
    async (formData) => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: true });
      try {
        const { token, company } = await login(formData);
        setStoredToken(token);
        setStoredCompany(company);
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: company });
        navigate("/loadboard", { replace: true });
      } catch (error) {
        console.error("Login error:", error);
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Login failed. Please check your credentials.",
        });
      }
    },
    [navigate]
  );

  const handleLogout = useCallback(() => {
    clearAuthData();
    dispatch({ type: ActionTypes.LOGOUT });
    navigate("/login", { replace: true });
  }, [navigate, clearAuthData]);


  const fetchAvailableShipments = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/shipment/available");
      return response.data.shipments;
    } catch (error) {
      throw error;
    }
  }, []);

  const handleCompanyRegister = useCallback(
    async (formData) => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: true });
      try {
        await registerCompany(formData);
        navigate("/login");
      } catch (error) {
        dispatch({
          type: ActionTypes.SET_ERROR,
          payload: "Registration failed. Please try again.",
        });
      }
    },
    [navigate]
  );

  const contextValue = useMemo(
    () => ({
      company: state.company,
      isAuthenticated: !!state.company,
      loading: state.loading,
      error: state.error,
      handleLogout,
      handleLogin,
      handleCompanyRegister,
      fetchAvailableShipments,
    }),
    [
      state.company,
      state.loading,
      state.error,
      handleLogout,
      handleLogin,
      handleCompanyRegister,
      fetchAvailableShipments,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext };
