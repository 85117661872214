import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faStar } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Typography,
  Modal,
  Card,
  CardContent,
  IconButton,
  Button,
  TextField,
  Rating,
  Divider,
} from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useAuth } from "../AuthContext/AuthContext";
import { format } from "date-fns";

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const CompanyCard = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #f7f9fc;
  border: 1px solid #e1e4e8;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const ModalContent = styled(Card)`
  max-width: 500px;
  width: 100%;
  padding: 25px;
  position: relative;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
`;

const ReviewBox = styled(Box)`
  padding: 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
`;

const ShippingCompany = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const { company } = useAuth();

  const fetchAllCompanies = async () => {
    try {
      const response = await axiosInstance.get("/company/all");
      setCompanies(response.data.companies || []);
    } catch (error) {
      enqueueSnackbar("Error fetching all companies.", { variant: "error" });
    }
  };

const fetchCompanyReviews = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/company/${companyId}/reviews`);
    if (response.data.success) {
      const reviewsWithDetails = response.data.reviews.map((review) => ({
        ...review,
        reviewerCompanyName:
          review.reviewerCompanyId?.companyName || "Anonymous",
        reviewerAddress: review.reviewerCompanyId?.companyAddress || "N/A",
        reviewerPhone: review.reviewerCompanyId?.phoneNumber || "N/A",
        reviewerEmail: review.reviewerCompanyId?.companyEmail || "N/A",
      }));
      setReviews(reviewsWithDetails);
    }
  } catch (error) {
    enqueueSnackbar("Error fetching reviews.", { variant: "error" });
  }
};


  const calculateAverageRating = (reviews = []) => {
    if (!Array.isArray(reviews) || reviews.length === 0) return "N/A";
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return (totalRating / reviews.length).toFixed(1);
  };

  useEffect(() => {
    if (company) {
      const oppositeType =
        company.companyType === "Delivery" ? "Shipping" : "Delivery";
      setFilteredCompanies(
        companies.filter((comp) => comp.companyType === oppositeType)
      );
    }
  }, [companies, company]);

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  const handleOpenModal = (company) => {
    setSelectedCompany(company);
    fetchCompanyReviews(company._id); // Fetch reviews separately on modal open
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCompany(null);
    setOpen(false);
    setRating(0);
    setReview("");
    setReviews([]);
  };

  const handleReviewSubmit = async () => {
    if (!review || !rating) {
      enqueueSnackbar("Please provide both a rating and a review.", {
        variant: "warning",
      });
      return;
    }

    try {
      const reviewData = {
        rating,
        comment: review,
        reviewerCompanyId: company._id,
        createdAt: new Date().toISOString(),
      };

      const response = await axiosInstance.post(
        `/company/${selectedCompany._id}/reviews`,
        reviewData
      );

      if (response.data.success) {
        enqueueSnackbar("Review submitted successfully!", {
          variant: "success",
        });
        setReviews((prevReviews) => [...prevReviews, reviewData]);
        setReview("");
        setRating(0);
      }
    } catch (error) {
      enqueueSnackbar("Error submitting review.", { variant: "error" });
    }
  };

  return (
    <Box padding="20px">
      <Typography variant="h5" gutterBottom>
        {company?.companyType === "Delivery"
          ? "Shipping Companies"
          : "Delivery Companies"}
      </Typography>
      <CompanyList>
        {filteredCompanies.map((company) => (
          <CompanyCard
            key={company._id}
            onClick={() => handleOpenModal(company)}
            whileHover={{ scale: 1.03 }}
            transition={{ duration: 0.2 }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{company.companyName}</Typography>
              <Box ml={2} display="flex" alignItems="center">
                <FontAwesomeIcon icon={faStar} color="gold" />
                <Typography ml={0.5} variant="body2">
                  {calculateAverageRating(company.reviews)}
                </Typography>
              </Box>
            </Box>
            <FontAwesomeIcon icon={faTruck} color="#007bff" size="lg" />
          </CompanyCard>
        ))}
      </CompanyList>

      <StyledModal open={open} onClose={handleCloseModal}>
        <ModalContent
          component={motion.div}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <CardContent>
            {selectedCompany && (
              <>
                <Typography variant="h6" gutterBottom>
                  {selectedCompany.companyName || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Type:</strong> {selectedCompany.companyType || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Address:</strong>{" "}
                  {selectedCompany.companyAddress || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong>{" "}
                  {selectedCompany.companyEmail || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Phone:</strong> {selectedCompany.phoneNumber || "N/A"}
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box mt={2}>
                  <Typography variant="subtitle1">Leave a Review</Typography>
                  <Rating
                    value={rating}
                    onChange={(event, newValue) => setRating(newValue)}
                  />
                  <TextField
                    label="Your Review"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReviewSubmit}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Submit Review
                  </Button>
                </Box>
                <Box mt={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reviews
                  </Typography>
                  {reviews.map((rev, index) => (
                    <ReviewBox
                      key={index}
                      component={motion.div}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                    >
                      <Typography variant="body2">
                        <strong>Rating:</strong> {rev.rating}
                        <FontAwesomeIcon
                          icon={faStar}
                          color="gold"
                          style={{ marginLeft: "5px" }}
                        />
                      </Typography>
                      <Typography variant="body2">
                        <strong>Comment:</strong> {rev.comment}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <strong>Date:</strong>{" "}
                        {format(new Date(rev.createdAt), "MMM dd, yyyy")}
                      </Typography>
                    </ReviewBox>
                  ))}
                </Box>
              </>
            )}
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: 5, right: 5 }}
            >
              X
            </IconButton>
          </CardContent>
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

export default ShippingCompany;
