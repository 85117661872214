// RegisterPrompt.js
import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PromptContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  background: #f0f4f8;
  min-height: 100vh;
  color: #333;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #02226d;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555;
`;

const RegisterButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #02226d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #02226d;
  }
`;

const RegisterPrompt = () => {
  const navigate = useNavigate();

  const handleRegisterRedirect = () => {
    navigate("/register");
  };

  return (
    <PromptContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Title>Access Restricted</Title>
      <Message>Please register or log in to access the Load Board.</Message>
      <RegisterButton onClick={handleRegisterRedirect}>
        Register Now
      </RegisterButton>
    </PromptContainer>
  );
};

export default RegisterPrompt;
