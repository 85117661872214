const validateEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !email.match(emailRegex) ? "Email not valid" : "";
};

const validatePhone = (phone) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phone && !phone.match(phoneRegex) ? "Phone number not valid" : "";
};

const validateName = (name) => {
  if (name.length < 5) return "Your full name is too short";
  if (name.length > 50) return "Try to make it short and meaningful";
  return "";
};

const validateMessage = (message) => {
  if (message.length < 5) return "Message is too short";
  if (message.length > 200) return "Try to make it short and meaningful";
  return "";
};

const validateSubject = (subject) => {
  if (subject.length < 2) return "Subject is too short";
  if (subject.length > 50) return "Try to make it short and meaningful";
  return "";
};

export {
  validateEmail,
  validateName,
  validateMessage,
  validatePhone,
  validateSubject,
};
