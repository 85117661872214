import React from "react";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import styled from "styled-components";

const NegotiationWrapper = styled.div`
  margin-top: 20px;
`;

const OfferList = styled.div`
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  max-height: 200px;
  overflow-y: auto;
`;

const LogEntry = styled(Typography)`
  padding: 8px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const NegotiationSection = ({
  offer,
  setOffer,
  onSubmitOffer,
  negotiationLog = [],
  loadingAction,
  logLoading,
}) => (
  <NegotiationWrapper>
    <Typography variant="h6" gutterBottom>
      Negotiation
    </Typography>
    <TextField
      label="Your Offer"
      variant="outlined"
      value={offer}
      onChange={(e) => setOffer(e.target.value)}
      fullWidth
      margin="normal"
      disabled={loadingAction || logLoading}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={onSubmitOffer}
      disabled={loadingAction}
      fullWidth
      sx={{ mt: 2 }}
    >
      {loadingAction ? <CircularProgress size={24} /> : "Submit Offer"}
    </Button>
    <OfferList>
      <Typography variant="subtitle1" gutterBottom>
        Negotiation Log
      </Typography>
      {logLoading ? (
        <CircularProgress size={24} />
      ) : negotiationLog.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No negotiation history yet.
        </Typography>
      ) : (
        negotiationLog.map((entry, index) => (
          <LogEntry key={index} variant="body2">
            {entry.sender} offered ${entry.offer} on{" "}
            {new Date(entry.date).toLocaleString()}
          </LogEntry>
        ))
      )}
    </OfferList>
  </NegotiationWrapper>
);

export default NegotiationSection;
