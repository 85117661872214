// NavLinks.js

import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "./AuthContext/AuthContext";
import "./HeaderNavbar.css";

const Nav = ({ closeMenu }) => {
  const { isAuthenticated, handleLogout } = useAuth();

  const links = [
    { path: "/", label: "Home" },
    { path: "/service", label: "Service" },
    { path: "/about", label: "About" },
    { path: "/loadboard", label: "LoadBoard" },
    { path: "/contact", label: "Contact" },
  ];

  const authLinks = isAuthenticated
    ? [
        {
          label: "Logout",
          onClick: async () => {
            await handleLogout();
            closeMenu();
          },
        },
      ]
    : [
        { path: "/login", label: "Login" },
        { path: "/register", label: "Register" },
      ];

  return (
    <ul className="nav-links-list">
      {links.map((link) => (
        <li key={link.label}>
          <NavLink
            to={link.path}
            onClick={closeMenu}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            {link.label}
          </NavLink>
        </li>
      ))}
      {authLinks.map((link) => (
        <li key={link.label}>
          {link.path ? (
            <NavLink
              to={link.path}
              onClick={closeMenu}
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              {link.label}
            </NavLink>
          ) : (
            <span onClick={link.onClick} className="logout-link">
              {link.label}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Nav;
