import React from "react";
import { Button, Tooltip } from "@mui/material";
import styled from "styled-components";

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ActionButtons = ({
  shipmentStatus,
  onPickup,
  onComplete,
  loadingAction,
}) => {
  return (
    <ActionButtonWrapper>
      {shipmentStatus === "Available" && (
        <Tooltip title="Start the shipment process" arrow>
          <Button
            variant="contained"
            color="primary"
            onClick={onPickup}
            disabled={loadingAction}
          >
            Book
          </Button>
        </Tooltip>
      )}
      {shipmentStatus === "In Progress" && (
        <>
          <Tooltip title="Complete the shipment" arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Complete button clicked");
                onComplete && onComplete("Completed"); // Trigger onComplete with status
              }}
              disabled={loadingAction}
            >
              Complete
            </Button>
          </Tooltip>
          <Tooltip title="Cancel the shipment" arrow>
            <Button
              variant="outlined"
              color="error"
              onClick={() => onComplete && onComplete("Cancelled")}
              disabled={loadingAction}
            >
              Cancel
            </Button>
          </Tooltip>
        </>
      )}
    </ActionButtonWrapper>
  );
};

export default ActionButtons;
