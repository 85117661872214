import axios from "axios";
import {
  getStoredToken,
  removeStoredToken,
} from "./LocalStorageManager/LocalStorageManager";

const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3003";

const axiosInstance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getStoredToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response, 
  (error) => {
    const { response } = error;

    if (response?.status === 401 || !response) {
      console.warn("Unauthorized or network error detected. Logging out.");

      removeStoredToken();

      window.location.href = "/login";
    } else if (response?.status === 500) {
      console.error("Server error:", error);
      alert("A server error occurred. Please try again later.");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
