import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "./Images/footerlogoimg.png";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const socialMediaIcons = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/thewrightlogisticsllc/",
    icon: faFacebook,
  },
  {
    name: "Twitter",
    href: "https://x.com/wrightlogistic",
    icon: faTwitter,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/thewrightlogisticsllc/",
    icon: faLinkedin,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/thewrightlogistics/",
    icon: faInstagram,
  },
];

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      <div className="footer-logo">
        <img src={footerLogo} alt="The Wright Logistics Logo" />
        <p className="slogan">The Wright way to move Freight</p>
        <p className="contact-info">Contact us: (949) 919-4017</p>
      </div>

      <div className="footer-links">
        <div className="link-section">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/service">Services</Link>
            </li>
            <li>
              <Link to="/loadboard">LoadBoard</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="link-section">
          <h4>Our Services</h4>
          <ul>
            <li>
              <Link to="/">Shipping</Link>
            </li>
            <li>
              <Link to="/">Delivery</Link>
            </li>
            <li>
              <Link to="/">Freight</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="newsletter">
        <h4>Subscribe to our Newsletter</h4>
        <p>Get the latest updates on logistics and freight.</p>
        <form>
          <input type="email" placeholder="Enter your email" />
          <button type="submit" className="submit-btn">
            Subscribe
          </button>
        </form>
      </div>

      <div className="social-icons">
        {socialMediaIcons.map((icon, index) => (
          <a
            key={index}
            href={icon.href}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Visit us on ${icon.name}`}
            className="social-icon"
          >
            <FontAwesomeIcon icon={icon.icon} />
          </a>
        ))}
      </div>
    </div>
    <div className="footer-bottom">
      <p>
        &copy; {new Date().getFullYear()} The Wright Logistics LLC. All rights
        reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
