// TruckInfo.js
import React from "react";
import styled from "styled-components";

const InputField = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const TruckInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TruckInfo = ({ trucks, setTrucks, readOnly = false }) => {
  const handleTruckInfoChange = (index, field, value) => {
    const updatedTrucks = trucks.map((truck, i) =>
      i === index ? { ...truck, [field]: value } : truck
    );
    setTrucks && setTrucks(updatedTrucks);
  };

  return (
    <TruckInfoContainer>
      {trucks.map((truck, index) => (
        <div key={index}>
          {["make", "model", "year", "vin", "licensePlate"].map((field) => (
            <InputField
              key={field}
              type="text"
              placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
              value={truck[field] || ""}
              onChange={(e) =>
                handleTruckInfoChange(index, field, e.target.value)
              }
              disabled={readOnly}
            />
          ))}
        </div>
      ))}
    </TruckInfoContainer>
  );
};

export default TruckInfo;
