// Dashboard.js
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useAuth } from "./AuthContext/AuthContext";
import DashboardContent from "./DashboardContent";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import CompanyInfo from "./CompanyInfo";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  background: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background: #0056b3;
  }
`;

const Dashboard = () => {
  const { company, updateCompanyInfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);

  // Fetch shipments for the company based on company ID
  const fetchShipments = useCallback(async () => {
    if (!company?.id) return;

    try {
      const { data } = await axiosInstance.get(
        `/shipment/${company.id}/shipment`
      );
      console.log("Fetched shipments data:", data); // Debug log

      if (data && data.shipments) {
        setShipments(data.shipments);
      }
    } catch (error) {
      console.error("Error fetching shipments:", error);
      enqueueSnackbar("Error fetching shipments.", { variant: "error" });
    }
  }, [company, enqueueSnackbar]);

  // Using useEffect to fetch shipments on component mount
  useEffect(() => {
    const initFetch = async () => {
      if (company) {
        await fetchShipments();
      }
    };

    initFetch(); // Call the async fetch function inside the useEffect
  }, [company, fetchShipments]);

  return (
    <DashboardContainer>
      <DashboardHeader>
        <ToggleButton onClick={() => setShowCompanyInfo((prev) => !prev)}>
          <FontAwesomeIcon icon={showCompanyInfo ? faEyeSlash : faEye} />
          {showCompanyInfo ? "Hide Company Info" : "Show Company Info"}
        </ToggleButton>
      </DashboardHeader>

      {showCompanyInfo && (
        <CompanyInfo company={company} updateCompanyInfo={updateCompanyInfo} />
      )}

      <DashboardContent
        shipments={shipments}
        onViewShipment={setSelectedShipment}
      />

      {selectedShipment && (
        <ShipmentDetailsDialog
          open={!!selectedShipment}
          onClose={() => setSelectedShipment(null)}
          shipment={selectedShipment}
        />
      )}
    </DashboardContainer>
  );
};

export default Dashboard;
