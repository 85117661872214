import React, { useState, useCallback } from "react";
import { Typography } from "@mui/material";
import ShipmentTable from "./ShipmentTable";
import ShipmentDetailsDialog from "./ShipmentDetailsDialog";
import { Container } from "./ViewShipmentsStyled";
import { useSnackbar } from "notistack";
import { useAuth } from "./AuthContext/AuthContext";
import axiosInstance from "./axiosInstance";
import { styled } from "@mui/material/styles";

const CustomContainer = styled(Container)({
  backgroundColor: "#f7f7f7",
  padding: "20px 0px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
});

const CustomTitle = styled(Typography)({
  color: "#3f51b5",
  fontWeight: "bold",
  marginBottom: "20px",
  textAlign: "center",
});

const ViewShipments = ({ shipments, loading, fetchShipments }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [offer, setOffer] = useState("");
  const [negotiationLog, setNegotiationLog] = useState([]);
  const [logLoading, setLogLoading] = useState(false);

  const handleViewShipment = useCallback(
    async (shipment) => {
      setSelectedShipment(shipment);
      setNegotiationLog([]);
      setLogLoading(true);

      try {
        const response = await axiosInstance.get(
          `/shipment/${shipment._id}/negotiation-log`
        );
        console.log("Negotiation log response:", response.data);
        setNegotiationLog(response.data.negotiationLog || []);
      } catch (error) {
        console.error("Error fetching negotiation log:", error);
        enqueueSnackbar("Failed to fetch negotiation log.", {
          variant: "error",
        });
      } finally {
        setLogLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  const handleCloseDialog = () => {
    setSelectedShipment(null);
    setOffer("");
  };

  const handlePickupShipment = useCallback(async () => {
    if (!selectedShipment || !company?.id) return;

    const agreedPrice = offer || selectedShipment.totalCost;
    setActionLoading(true);

    try {
      const { data } = await axiosInstance.patch(
        `/shipment/${selectedShipment._id}/pickup`,
        {
          deliveryCompanyId: company.id,
          agreedPrice,
        }
      );
      console.log("Pickup response:", data);

      if (data.success) {
        enqueueSnackbar(data.message || "Shipment picked up successfully!", {
          variant: "success",
        });
        handleCloseDialog();
      } else {
        throw new Error(data.error || "Unexpected error occurred.");
      }
    } catch (error) {
      const message =
        error.response?.data?.error ||
        error.message ||
        "Error picking up shipment.";
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setActionLoading(false);
    }
  }, [selectedShipment, company, offer, enqueueSnackbar]);

  const handleSubmitOffer = useCallback(async () => {
    if (!selectedShipment || !company?.id) return;

    setActionLoading(true);
    try {
      const response = await axiosInstance.post(
        `/shipment/${selectedShipment._id}/negotiate`,
        {
          offer,
          companyId: company.id,
          companyType: company.companyType,
        }
      );
      console.log("Submit offer response:", response.data);

      enqueueSnackbar("Offer submitted successfully!", { variant: "success" });
      setNegotiationLog((prev) => [
        ...prev,
        { offer, sender: company.companyName, date: new Date().toISOString() },
      ]);
      setOffer("");
    } catch (error) {
      const message = error.response?.data?.error || "Error submitting offer";
      console.error("Error submitting offer:", error);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setActionLoading(false);
    }
  }, [selectedShipment, company, offer, enqueueSnackbar]);
const handleCompleteShipment = async (status = "Completed") => {
  if (!selectedShipment) {
    console.log("No shipment selected to complete.");
    return;
  }

  setActionLoading(true);
  console.log(
    "Attempting to update shipment:",
    selectedShipment._id,
    "to status:",
    status
  );

  try {
    const response = await axiosInstance.patch(
      `/shipment/${selectedShipment._id}/complete`,
      { status }
    );

    console.log("Complete shipment response:", response.data);

    if (response.data.success) {
      enqueueSnackbar(`Shipment ${status.toLowerCase()} successfully!`, {
        variant: "success",
      });
      handleCloseDialog();
      fetchShipments(); // Refresh shipments list after completion
    } else {
      throw new Error(response.data.message || "Unexpected error occurred.");
    }
  } catch (error) {
    const message =
      error.response?.data?.message || `Error ${status.toLowerCase()} shipment`;
    console.error(`Error ${status.toLowerCase()} shipment:`, message);
    enqueueSnackbar(message, { variant: "error" });
  } finally {
    setActionLoading(false);
  }
};


  return (
    <CustomContainer>
      <CustomTitle variant="h5">Your Shipments</CustomTitle>
      {loading ? (
        <Typography variant="h6" color="textSecondary" align="center">
          Loading shipments...
        </Typography>
      ) : shipments.length === 0 ? (
        <Typography variant="h6" color="textSecondary" align="center">
          No shipments found.
        </Typography>
      ) : (
        <ShipmentTable shipments={shipments} onView={handleViewShipment} />
      )}
      {selectedShipment && (
        <ShipmentDetailsDialog
          open={Boolean(selectedShipment)}
          onClose={handleCloseDialog}
          shipment={selectedShipment}
          onPickup={handlePickupShipment}
          onComplete={handleCompleteShipment} // Ensure this prop is defined and correctly passed
          offer={offer}
          setOffer={setOffer}
          onSubmitOffer={handleSubmitOffer}
          negotiationLog={negotiationLog}
          logLoading={logLoading}
          loadingAction={actionLoading}
        />
      )}
    </CustomContainer>
  );
};

export default ViewShipments;
