// ShipmentStyles.js
import styled from "styled-components";
import { motion } from "framer-motion";
import { Button, Typography } from "@mui/material";

export const ShipmentFormContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

export const FormWrapper = styled(motion.div)`
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

export const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5em;
`;

export const StyledButton = styled(Button)`
  background-color: #007bff;
  color: white;
  width: 100%;
  &:hover {
    background-color: #0056b3;
  }
`;
