// ShipmentDetailsSection.js
import React, { useState } from "react";
import { Typography, Box, Divider, Modal, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faDollarSign,
  faWeightHanging,
  faInfoCircle,
  faCalendarAlt,
  faRoute,
  faSyncAlt,
  faTruck,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
`;

const IconText = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  strong {
    margin-left: 5px;
    font-weight: 600;
  }
  cursor: pointer;
`;

const MapModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MapContent = styled(Box)`
  width: 90%;
  height: 70vh;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
`;

const ShipmentDetailsSection = ({ shipment }) => {
  const [mapOpen, setMapOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState(null);

  if (!shipment) return null;

  const shipper = shipment?.shipperId;

  const formatDateTime = (date) =>
    date ? new Date(date).toLocaleString() : "N/A";

  const fetchCoordinates = async (address) => {
    try {
      const { data } = await axios.get(
        "https://api.opencagedata.com/geocode/v1/json",
        {
          params: {
            q: `${address.street}, ${address.city}, ${address.state}, ${address.zipCode}`,
            key: "701124585ce74f4084a3bb6e0ca7ea4c",
            limit: 1,
          },
        }
      );
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry;
        return [lat, lng];
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
    return null;
  };

  const handleOpenMap = async (address) => {
    if (address && address.lat && address.lng) {
      setMapCenter([address.lat, address.lng]);
    } else {
      const coordinates = await fetchCoordinates(address);
      if (coordinates) {
        setMapCenter(coordinates);
      } else {
        alert("Coordinates could not be found for this address.");
      }
    }
    setMapOpen(true);
  };

  return (
    <Container>
      <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 10 }}>
        Shipment Details
      </Typography>
      <Divider />

      <DetailRow>
        <IconText>
          <FontAwesomeIcon icon={faTruck} style={{ marginRight: 8 }} />
          <strong>Shipper Company:</strong> {shipper?.companyName || "N/A"}
        </IconText>
        <IconText>
          <FontAwesomeIcon icon={faDollarSign} style={{ marginRight: 8 }} />
          <strong>Total Cost:</strong> $
          {shipment.totalCost?.toFixed(2) || "N/A"}
        </IconText>
      </DetailRow>

      <DetailRow>
        <IconText onClick={() => handleOpenMap(shipment.origin)}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
          <strong>Origin:</strong> {shipment.origin?.street || "N/A"},{" "}
          {shipment.origin?.city || "N/A"}, {shipment.origin?.state || "N/A"} -{" "}
          {shipment.origin?.zipCode || "N/A"}
        </IconText>
        <IconText onClick={() => handleOpenMap(shipment.destination)}>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
          <strong>Destination:</strong> {shipment.destination?.street || "N/A"},{" "}
          {shipment.destination?.city || "N/A"},{" "}
          {shipment.destination?.state || "N/A"} -{" "}
          {shipment.destination?.zipCode || "N/A"}
        </IconText>
      </DetailRow>

      <DetailRow>
        <IconText>
          <FontAwesomeIcon icon={faWeightHanging} style={{ marginRight: 8 }} />
          <strong>Weight:</strong> {shipment.weight || "N/A"} lbs
        </IconText>
        <IconText>
          <FontAwesomeIcon icon={faRoute} style={{ marginRight: 8 }} />
          <strong>Total Miles:</strong> {shipment.totalMiles || "N/A"} miles
        </IconText>
      </DetailRow>

      <DetailRow>
        <IconText>
          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
          <strong>Pickup Date:</strong> {formatDateTime(shipment.pickupTime)}
        </IconText>
        <IconText>
          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
          <strong>Drop-off Date:</strong> {formatDateTime(shipment.dropOffTime)}
        </IconText>
      </DetailRow>

      <DetailRow>
        <IconText>
          <FontAwesomeIcon
            icon={shipment.isRoundTrip ? faSyncAlt : faRoute}
            style={{ marginRight: 8 }}
          />
          <strong>Trip Type:</strong>{" "}
          {shipment.isRoundTrip ? "Round Trip" : "One Way"}
        </IconText>
        <IconText>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 8 }} />
          <strong>Description:</strong> {shipment.description || "N/A"}
        </IconText>
      </DetailRow>

      <DetailRow>
        <IconText>
          <FontAwesomeIcon icon={faPhone} style={{ marginRight: 8 }} />
          <strong>Contact Phone:</strong> {shipper?.phoneNumber || "N/A"}
        </IconText>
        <IconText>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} />
          <strong>Email:</strong> {shipper?.companyEmail || "N/A"}
        </IconText>
      </DetailRow>

      <MapModal open={mapOpen} onClose={() => setMapOpen(false)}>
        <MapContent>
          {mapCenter ? (
            <MapContainer
              center={mapCenter}
              zoom={13}
              style={{ width: "100%", height: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={mapCenter}>
                <Popup>Location</Popup>
              </Marker>
            </MapContainer>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No location data available.
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setMapOpen(false)}
            sx={{ mt: 2 }}
          >
            Close Map
          </Button>
        </MapContent>
      </MapModal>
    </Container>
  );
};

export default ShipmentDetailsSection;
