// ViewShipmentsStyled.js
import styled from "styled-components";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5em;
`;
