import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import ShipmentDetailsSection from "./ShipmentDetailsSection";
import ActionButtons from "./ActionButtons";
import NegotiationSection from "./NegotiationSection";

const ShipmentDetailsDialog = ({
  open,
  onClose,
  shipment,
  onPickup,
  onComplete,
  offer,
  setOffer,
  onSubmitOffer,
  negotiationLog,
  loadingAction,
  logLoading,
}) => {
  if (!shipment) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h5" component="div" style={{ fontWeight: "bold" }}>
          Shipment Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ShipmentDetailsSection shipment={shipment} />
        {shipment.status === "Available" && (
          <NegotiationSection
            offer={offer}
            setOffer={setOffer}
            onSubmitOffer={onSubmitOffer}
            negotiationLog={negotiationLog}
            loadingAction={loadingAction}
            logLoading={logLoading}
          />
        )}
        <ActionButtons
          shipmentStatus={shipment.status}
          onPickup={onPickup}
          onComplete={onComplete} // Pass onComplete directly here
          loadingAction={loadingAction}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShipmentDetailsDialog;
