import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruckFast,
  faShippingFast,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./service.css";
import { FormTitle } from "../components/formTitle";
import Header from "./Header";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.15, delayChildren: 0.3 },
  },
};

const itemVariants = {
  hidden: { y: 30, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 80, damping: 10 },
  },
};

const iconVariants = {
  hover: { scale: 1.1, transition: { yoyo: Infinity, duration: 0.4 } },
};

const Services = () => (
  <div>
    <Header title="Services" />
    {/* <FormTitle text="Our Services" /> */}

    <motion.div
      className="services-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="service-item"
        variants={itemVariants}
        whileHover="hover"
      >
        <motion.div variants={iconVariants}>
          <FontAwesomeIcon icon={faShippingFast} size="3x" />
        </motion.div>
        <h2>Interactive Loadboard</h2>
        <p>
          Access our dynamic loadboard for real-time shipping opportunities.
        </p>
      </motion.div>

      <motion.div
        className="service-item"
        variants={itemVariants}
        whileHover="hover"
      >
        <motion.div variants={iconVariants}>
          <FontAwesomeIcon icon={faTruckFast} size="3x" />
        </motion.div>
        <h2>Fast Delivery</h2>
        <p>Ensuring timely delivery of your packages nationwide.</p>
      </motion.div>

      <motion.div
        className="service-item"
        variants={itemVariants}
        whileHover="hover"
      >
        <motion.div variants={iconVariants}>
          <FontAwesomeIcon icon={faSyncAlt} size="3x" />
        </motion.div>
        <h2>Seamless Process</h2>
        <p>Our optimized process ensures efficiency from start to finish.</p>
      </motion.div>
    </motion.div>
  </div>
);

export default Services;
