import React from "react";
import { motion } from "framer-motion";
import image6 from "../components/Images/image 6.png";
import image7 from "../components/Images/image 7.png";
import image8 from "../components/Images/image 8.png";
import image9 from "../components/Images/image 9.png";
import "./clients.css";

const clientImages = [image6, image7, image8, image9];

const Clients = () => {
  return (
    <motion.section
      className="homeClient"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="homeContainer">
        <header className="clients-header">
          <motion.h3
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 50 }}
          >
            Our Clients
          </motion.h3>
          <motion.h1
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, type: "spring", stiffness: 50 }}
          >
            Our Awesome Partners
          </motion.h1>
        </header>
        <motion.div
          className="clients-logo-grid"
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
            hidden: { opacity: 0 },
          }}
        >
          {clientImages.map((image, index) => (
            <motion.div
              key={index}
              className="client-logo-img"
              whileHover={{ scale: 1.2 }}
              transition={{ duration: 0.3 }}
            >
              <img src={image} alt={`Client ${index + 1}`} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Clients;
