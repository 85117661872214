// DashboardContent.js
import React, { useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import ShipmentTable from "./ShipmentTable";
import { useAuth } from "./AuthContext/AuthContext";

const SectionContainer = styled.div`
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
`;

const SectionHeader = styled.h2`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 15px;
`;

const EmptyMessage = styled.p`
  color: #666;
  font-size: 0.9rem;
`;

const DashboardContent = ({ shipments = [], onViewShipment }) => {
  const { company } = useAuth();
  const shipmentType =
    company?.companyType === "Delivery" ? "Deliveries" : "Shipments";

  // Separate shipments based on status
  const inProgressShipments = shipments.filter(
    (shipment) => shipment.status === "In Progress"
  );
  const completedShipments = shipments.filter(
    (shipment) => shipment.status === "Completed"
  );

  useEffect(() => {
    console.log("Shipments in DashboardContent:", shipments); // Debug log
    console.log("In Progress Shipments:", inProgressShipments);
    console.log("Completed Shipments:", completedShipments);
  }, [shipments, inProgressShipments, completedShipments]);

  const renderShipmentsTable = useCallback(
    (shipmentList, emptyMessage) =>
      shipmentList.length > 0 ? (
        <ShipmentTable shipments={shipmentList} onView={onViewShipment} />
      ) : (
        <EmptyMessage>{emptyMessage}</EmptyMessage>
      ),
    [onViewShipment]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <SectionContainer>
        <SectionHeader>In Progress {shipmentType}</SectionHeader>
        {renderShipmentsTable(
          inProgressShipments,
          `No in-progress ${shipmentType.toLowerCase()} found.`
        )}
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>Completed {shipmentType}</SectionHeader>
        {renderShipmentsTable(
          completedShipments,
          `No completed ${shipmentType.toLowerCase()} found.`
        )}
      </SectionContainer>
    </motion.div>
  );
};

export default DashboardContent;
