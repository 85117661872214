import React from "react";
import { motion } from "framer-motion";
import "./HomeAbout.css";

const About = () => {
  return (
    <motion.section
      className="About"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="About-Wrapper">
        <motion.div
          className="About-Header"
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, type: "spring", stiffness: 50 }}
        >
          <h3>About Us -</h3>
          <h1>Who We Are</h1>
          <p>
            At <strong>The Wright Logistics</strong>, we’re redefining logistics
            with a focus on precision, efficiency, and a customer-first
            approach. Our family-owned company ensures every shipment is handled
            with care and urgency, enabling businesses to excel in a competitive
            landscape.
          </p>
        </motion.div>
        <div className="About-Content">
          <motion.div
            className="About-Card"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 50 }}
          >
            <div className="Card-Icon">
              <motion.i
                className="fas fa-bullseye"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              />
            </div>
            <h2>Our Vision</h2>
            <p>
              Bridging the gap between shippers and carriers, creating seamless
              and efficient logistics experiences that make every delivery part
              of a larger success story.
            </p>
          </motion.div>
          <motion.div
            className="About-Card"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, type: "spring", stiffness: 50 }}
          >
            <div className="Card-Icon">
              <motion.i
                className="fas fa-truck"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              />
            </div>
            <h2>Why Choose Us?</h2>
            <ul>
              <li>Family-Driven Excellence</li>
              <li>Unmatched Same-Day Delivery Speed</li>
              <li>Customer-Centric Approach with 24/7 Support</li>
            </ul>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
