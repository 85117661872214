import React, { useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import ShipmentComponent from "../components/ShipmentForm/ShipmentComponent";
import { useAuth } from "../components/AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import RegisterPrompt from "../components/RegisterPrompt";
import "./Loadboard.css";

const LoadBoard = () => {
  const { company, fetchAvailableShipments, loading: authLoading } = useAuth();
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchShipments = useCallback(async () => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const fetchedShipments = await fetchAvailableShipments({ signal });
      setShipments(Array.isArray(fetchedShipments) ? fetchedShipments : []);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Failed to load shipments:", error);
        enqueueSnackbar("Failed to load shipments.", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }

    return () => controller.abort();
  }, [fetchAvailableShipments, enqueueSnackbar]);

  useEffect(() => {
    if (company) {
      fetchShipments();
    } else {
      setIsLoading(false);
    }
  }, [company, fetchShipments]);

  if (!authLoading && !company) {
    return <RegisterPrompt />;
  }

  if (authLoading || isLoading) {
    // return (
    //   <div className="loading-container">
    //     <CircularProgress />
    //   </div>
    // );
  }

  return (
    <motion.div
      className="loadboard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Load Board</h2>
      <div className="loadboard-content">
        <ShipmentComponent
          onShipmentSubmit={fetchShipments}
          shipments={shipments}
        />
      </div>
    </motion.div>
  );
};

export default LoadBoard;
