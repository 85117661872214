import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faHeadset,
  faShippingFast,
  faTags,
  faClipboardList,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import Struck from "./Images/struck.png";
// import miniTruck from "./Images/image 2.png";
import "./HomeServices.css";

const HomeServices = () => {
  return (
    <div

    >
      <div className="container">
        <div className="service-section">
          <div className="service-section-left">
            <motion.div
              className="home-headings"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <h3>Our Services</h3>
              <h1>What We Offer</h1>
            </motion.div>

            <motion.p
              className="home-service-description"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
            >
              Explore the many services designed to enhance your shipping
              experience. Our offerings include seamless state-wide delivery,
              Same Day Delivery, 24X7 Support, enticing Discounts, and a
              User-Friendly interface. We prioritize our clients' needs,
              ensuring they receive exceptional service with every interaction.
            </motion.p>

            <div className="service-list">
              <ServiceItem
                title="State Wide Delivery"
                description="Efficient statewide logistics management."
                icon={faTruck}
              />
              <ServiceItem
                title="Support 24X7"
                description="Round-the-clock support for your logistics."
                icon={faHeadset}
              />
              <ServiceItem
                title="Same Day Delivery"
                description="Guaranteed same-day delivery for urgent shipments."
                icon={faShippingFast}
              />
              <ServiceItem
                title="Discount"
                description="Exciting discounts to make shipping affordable."
                icon={faTags}
              />
              <ServiceItem
                title="Load Posting"
                description="Easy load posting to streamline logistics."
                icon={faClipboardList}
              />
              <ServiceItem
                title="User Friendly"
                description="Designed with user-friendliness in mind."
                icon={faUserCheck}
              />
            </div>
          </div>

          <div className="service-section-right">
            <motion.img
              src={Struck}
              alt="Truck"
              className="service-truck-img"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
            />
            {/* <motion.img
              src={miniTruck}
              alt="Mini Truck"
              className="mini-truck-img"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 0.8, ease: "easeOut" }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceItem = ({ title, description, icon }) => (
  <motion.div
    className="service-item"
    whileHover={{ scale: 1.05 }} // Only scaling, no shadow animation
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="icon-circle">
      <FontAwesomeIcon icon={icon} size="lg" color="#02226D" />
    </div>
    <div className="service-details">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  </motion.div>
);

export default HomeServices;
