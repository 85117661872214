// calculateTotalMiles.js
import { getDistance } from "geolib";

export default function calculateTotalMiles(origin, destination, isRoundTrip) {
  const oneWayMiles =
    getDistance(
      {
        latitude: origin.geometry.location.lat,
        longitude: origin.geometry.location.lng,
      },
      {
        latitude: destination.geometry.location.lat,
        longitude: destination.geometry.location.lng,
      }
    ) / 1609.34;
  return isRoundTrip ? oneWayMiles * 2 : oneWayMiles;
}
