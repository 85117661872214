// ShipmentComponent.js
import React, { useState, useMemo, useCallback } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import PostShipment from "../PostShipment";
import ViewShipments from "../ViewShipments";
import ShippingCompany from "../ShippingCompany/ShippingCompany";
import DeliveryCompany from "../DeliveryCompany/DeliveryCompany";
import { useAuth } from "../AuthContext/AuthContext";
import "./ShipmentComponent.css";
import Dashboard from "../Dashboard";

const ShipmentComponent = ({ onShipmentSubmit, shipments }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { company } = useAuth();

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const renderTabContent = useMemo(() => {
    switch (activeTab) {
      case 0:
        return <PostShipment onShipmentSubmit={onShipmentSubmit} />;
      case 1:
        return <ViewShipments shipments={shipments} />;
      case 2:
        return <Dashboard />;
      case 3:
        return company?.companyType === "Delivery" ? (
          <ShippingCompany />
        ) : (
          <DeliveryCompany />
        );
      default:
        return null;
    }
  }, [activeTab, onShipmentSubmit, shipments, company?.companyType]);

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="shipment tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ paddingX: 1 }}
        >
          <Tab label="Post Shipment" />
          <Tab label="View Shipments" />
          <Tab label="Dashboard" />
          {company?.companyType === "Delivery" && (
            <Tab label="Shipping Company Profiles" />
          )}
          {company?.companyType === "Shipping" && (
            <Tab label="Delivery Company Profiles" />
          )}
        </Tabs>
      </AppBar>
      <Box sx={{ padding: 2 }}>{renderTabContent}</Box>
    </>
  );
};

export default React.memo(ShipmentComponent);
