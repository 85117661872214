// CompanyInfo.js
import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import TruckInfo from "./TruckInfo";

const CompanyInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 15px;
  max-width: 100%;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
  font-size: 0.9rem;
`;

const InputField = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const EditButton = styled.button`
  background: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #0056b3;
  }
`;

const CompanyInfo = ({ company, updateCompanyInfo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedCompany, setEditedCompany] = useState({
    ...company,
    trucks: company.trucks || [
      { make: "", model: "", year: "", vin: "", licensePlate: "" },
    ],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateCompanyInfo(editedCompany);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update company info:", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedCompany({ ...company });
  };

  return (
    <CompanyInfoContainer>
      {isEditing ? (
        <InfoGrid>
          {Object.keys(editedCompany).map((key) =>
            key !== "trucks" ? (
              <InputField
                key={key}
                type="text"
                name={key}
                value={editedCompany[key]}
                onChange={handleInputChange}
                placeholder={key.replace(/([A-Z])/g, " $1")}
              />
            ) : (
              <TruckInfo
                trucks={editedCompany.trucks}
                setTrucks={(updatedTrucks) =>
                  setEditedCompany((prev) => ({
                    ...prev,
                    trucks: updatedTrucks,
                  }))
                }
              />
            )
          )}
          <EditButton onClick={handleSave}>
            <FontAwesomeIcon icon={faSave} /> Save
          </EditButton>
          <EditButton onClick={handleCancel}>
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </EditButton>
        </InfoGrid>
      ) : (
        <InfoGrid>
          {Object.entries(company).map(([key, value]) =>
            key !== "id" ? (
              <InfoCard key={key}>
                <strong>{key.replace(/([A-Z])/g, " $1")}: </strong>
                {key === "trucks" ? (
                  <TruckInfo trucks={value} readOnly />
                ) : (
                  value
                )}
              </InfoCard>
            ) : null
          )}
          <EditButton onClick={() => setIsEditing(true)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </EditButton>
        </InfoGrid>
      )}
    </CompanyInfoContainer>
  );
};

export default CompanyInfo;
