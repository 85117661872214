import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Login.css";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import Header from "../../pages/Header";

const Login = () => {
  const { handleLogin } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let isMounted = true; // Check if the component is still mounted

    try {
      await handleLogin(formData);
      if (isMounted) {
        enqueueSnackbar("Login successful!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error during login:", error);
      if (isMounted) {
        enqueueSnackbar(
          "Failed to log in. Please check your credentials and try again.",
          { variant: "error" }
        );
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }

    return () => {
      isMounted = false; // Cleanup function to prevent state update on unmount
    };
  };

  useEffect(() => {
    return () => {
      setIsLoading(false); // Cleanup if component is unmounted during loading
    };
  }, []);

  return (
    <div>
      <Header title="Login" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="login-container"
      >
        <form onSubmit={handleFormSubmit} className="login-form">
          <h2>Login</h2>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              name="username"
              id="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <motion.button
              type="submit"
              disabled={isLoading}
              className="submit-btn"
            >
              {isLoading ? "Logging in..." : "Login"}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default Login;
