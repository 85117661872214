import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import { useAuth } from "../AuthContext/AuthContext";
import {
  Box,
  Typography,
  Modal,
  Card,
  CardContent,
  TextField,
  Button,
  Divider,
  Rating,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faWarehouse,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { motion } from "framer-motion";
import { format } from "date-fns";

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const CompanyCard = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #f3f4f6;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const ModalContent = styled(Card)`
  max-width: 400px;
  width: 90%;
  padding: 20px;
  position: relative;
  max-height: 80vh;
  overflow-y: auto;

  @media (max-width: 600px) {
    width: 100%;
    padding: 15px;
  }
`;

const ReviewBox = styled(Box)`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const ReviewContainer = styled(Box)`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const DeliveryCompany = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [open, setOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const getCompanyIcon = (companyType) => {
    return companyType === "Delivery" ? faWarehouse : faTruck;
  };

  const fetchAllCompanies = async () => {
    try {
      const response = await axiosInstance.get(
        `/company/${company.id}/related-companies`
      );
      setFilteredCompanies(response.data.companies || []);
    } catch (error) {
      enqueueSnackbar("Error fetching companies.", { variant: "error" });
    }
  };

  const fetchCompanyReviews = async (companyId) => {
    try {
      const response = await axiosInstance.get(`/company/${companyId}/reviews`);
      if (response.data.success) {
        const formattedReviews = response.data.reviews.map((review) => ({
          ...review,
          reviewerCompanyName:
            review.reviewerCompanyId?.companyName || "Anonymous",
          reviewerAddress: review.reviewerCompanyId?.companyAddress || "N/A",
          reviewerPhone: review.reviewerCompanyId?.phoneNumber || "N/A",
          reviewerEmail: review.reviewerCompanyId?.companyEmail || "N/A",
        }));
        setReviews(formattedReviews);
      } else {
        enqueueSnackbar("Failed to fetch reviews.", { variant: "warning" });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching reviews. Please try again later.", {
        variant: "error",
      });
    }
  };

  const calculateAverageRating = (reviews) => {
    if (!reviews.length) return "N/A";
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return (totalRating / reviews.length).toFixed(1);
  };

  const handleOpenModal = (company) => {
    if (!company || !company.id) {
      console.error("Invalid company selected:", company);
      return;
    }
    setSelectedCompany(company);
    fetchCompanyReviews(company.id);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCompany(null);
    setOpen(false);
    setRating(0);
    setReview("");
    setReviews([]);
  };

  const handleReviewSubmit = async () => {
    if (!selectedCompany || !selectedCompany.id) {
      enqueueSnackbar("Please select a valid company to review.", {
        variant: "warning",
      });
      return;
    }

    if (!review || !rating) {
      enqueueSnackbar("Please provide both a rating and a review.", {
        variant: "warning",
      });
      return;
    }

    const reviewData = {
      rating,
      comment: review,
      reviewerCompanyId: company.id,
    };

    try {
      const response = await axiosInstance.post(
        `/company/${selectedCompany.id}/reviews`,
        reviewData
      );
      if (response.data.success) {
        enqueueSnackbar("Review submitted successfully!", {
          variant: "success",
        });
        setReviews((prevReviews) => [...prevReviews, response.data.review]);
        setReview("");
        setRating(0);
      }
    } catch (error) {
      enqueueSnackbar("Error submitting review.", { variant: "error" });
    }
  };

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  return (
    <Box padding="20px">
      <Typography variant="h5" gutterBottom>
        {company?.companyType === "Delivery"
          ? "Shipping Companies"
          : "Delivery Companies"}
      </Typography>
      <CompanyList>
        {filteredCompanies.map((comp) => (
          <CompanyCard
            key={comp.id}
            onClick={() => handleOpenModal(comp)}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{comp.companyName}</Typography>
              <Box ml={2} display="flex" alignItems="center">
                <FontAwesomeIcon icon={faStar} color="gold" />
                <Typography ml={0.5} variant="body2">
                  {calculateAverageRating(comp.reviews)}
                </Typography>
              </Box>
            </Box>
            <FontAwesomeIcon
              icon={getCompanyIcon(company.companyType)}
              color="#007bff"
              size="lg"
            />
          </CompanyCard>
        ))}
      </CompanyList>

      <StyledModal open={open} onClose={handleCloseModal}>
        <ModalContent
          component={motion.div}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <CardContent>
            {selectedCompany && (
              <>
                <Typography variant="h6" gutterBottom>
                  {selectedCompany.companyName || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Type:</strong> {selectedCompany.companyType || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Address:</strong>{" "}
                  {selectedCompany.companyAddress || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong>{" "}
                  {selectedCompany.companyEmail || "N/A"}
                </Typography>
                <Typography variant="body2">
                  <strong>Phone:</strong> {selectedCompany.phoneNumber || "N/A"}
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />

                <Box mt={2}>
                  <Typography variant="subtitle1">Leave a Review</Typography>
                  <Rating
                    value={rating}
                    onChange={(e, newValue) => setRating(newValue)}
                  />
                  <TextField
                    label="Your Review"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReviewSubmit}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    Submit Review
                  </Button>
                </Box>

                <Box mt={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reviews
                  </Typography>
                  <ReviewContainer>
                    {reviews.map((rev, index) => (
                      <ReviewBox
                        key={index}
                        component={motion.div}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        <Typography variant="body2">
                          <strong>Rating:</strong> {rev.rating}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Comment:</strong> {rev.comment}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <strong>Date:</strong>{" "}
                          {format(new Date(rev.createdAt), "MMM dd, yyyy")}
                        </Typography>
                      </ReviewBox>
                    ))}
                  </ReviewContainer>
                </Box>
              </>
            )}
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: 5, right: 5 }}
            >
              X
            </IconButton>
          </CardContent>
        </ModalContent>
      </StyledModal>
    </Box>
  );
};

export default DeliveryCompany;
