import React, { useState } from "react";
import { motion } from "framer-motion";
import { axiosInstance } from "./config";
import "./LandingContactForm.css";

const LandingContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    try {
      const response = await axiosInstance.post("/contact", formData);
      const result = await response.data;
      alert(result.status);
      setStatus("Submit");
      setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
    } catch (error) {
      console.error("Error sending message", error);
      setStatus("Error");
    }
  };

  return (
    <motion.div
      className="enhanced-contact-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <motion.div
        className="contact-form-wrapper"
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      >
        <div className="contact-form-headers">
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Contact Us
          </motion.h3>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            GET IN TOUCH
          </motion.h1>
        </div>
        <form onSubmit={handleSubmit} className="enhanced-main-form">
          <div className="contact-input-group">
            <input
              type="text"
              name="name"
              required
              placeholder="Your Name"
              onChange={handleChange}
              value={formData.name}
            />
            <input
              type="email"
              name="email"
              required
              placeholder="Your Email"
              onChange={handleChange}
              value={formData.email}
            />
          </div>

          <div className="contact-input-group">
            <input
              type="text"
              name="phone"
              required
              placeholder="Phone Number"
              onChange={handleChange}
              value={formData.phone}
            />
            <input
              type="text"
              name="subject"
              required
              placeholder="Subject"
              onChange={handleChange}
              value={formData.subject}
            />
          </div>

          <div className="contact-textarea">
            <textarea
              name="message"
              required
              placeholder="Your Message"
              onChange={handleChange}
              value={formData.message}
            />
          </div>

          <motion.button
            type="submit"
            className="enhanced-submit-btn"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {status}
          </motion.button>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default LandingContactForm;
