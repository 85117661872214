import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrash,
  faArrowRight,
  faArrowsAltH,
  faTruck,
  faRulerHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const ActionButton = styled(Button)`
  margin-right: 8px;
  padding: 10px 15px;
`;

const ShipmentTableContainer = styled(TableContainer)`
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;

const MobileShipmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileShipmentCard = styled(Paper)`
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  color: #007bff;
  margin: 0px 8px;
`;

const ShipmentTable = ({ shipments, onView, onDelete }) => (
  <>
    <ShipmentTableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Shipper</TableCell>
            <TableCell>Route</TableCell>
            <TableCell>Truck Type & Size</TableCell>
            <TableCell>Total Cost</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipments.map((shipment) => (
            <TableRow key={shipment._id}>
              <TableCell>{shipment.shipperId?.companyName}</TableCell>
              <TableCell>
                {shipment.origin.city}, {shipment.origin.state}
                {shipment.isRoundTrip ? (
                  <DirectionIcon icon={faArrowsAltH} title="Round Trip" />
                ) : (
                  <DirectionIcon icon={faArrowRight} title="One Way" />
                )}
                {shipment.destination.city}, {shipment.destination.state}
              </TableCell>
              <TableCell>
                <FontAwesomeIcon icon={faTruck} style={{ marginRight: 5 }} />
                {shipment.truckType}
                <FontAwesomeIcon
                  icon={faRulerHorizontal}
                  style={{ margin: "0 5px" }}
                />
                {shipment.truckSize} ft
              </TableCell>
              <TableCell>${shipment.totalCost}</TableCell>
              <TableCell>#{shipment.shipmentQuantity}</TableCell>

              <TableCell>{shipment.status}</TableCell>
              <TableCell>
                <ActionButton onClick={() => onView(shipment)}>
                  <FontAwesomeIcon icon={faEye} />
                </ActionButton>
                <ActionButton onClick={() => onDelete(shipment._id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </ActionButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ShipmentTableContainer>

    {/* Mobile Card View */}
    <MobileShipmentList>
      {shipments.map((shipment) => (
        <MobileShipmentCard key={shipment._id}>
          <Typography variant="subtitle1" fontWeight="bold">
            {shipment.shipperId?.companyName}
          </Typography>
          <Typography variant="body2">
            <strong>Route:</strong> {shipment.origin.city},{" "}
            {shipment.origin.state}
            {shipment.isRoundTrip ? (
              <DirectionIcon icon={faArrowsAltH} title="Round Trip" />
            ) : (
              <DirectionIcon icon={faArrowRight} title="One Way" />
            )}
            {shipment.destination.city}, {shipment.destination.state}
          </Typography>
          <Typography variant="body2">
            <strong>Truck:</strong> <FontAwesomeIcon icon={faTruck} />{" "}
            {shipment.truckType}, {shipment.truckSize} ft
          </Typography>
          <Typography variant="body2">
            <strong>Total Cost:</strong> ${shipment.totalCost}
          </Typography>
          <Typography variant="body2">
            <strong>Status:</strong> {shipment.status}
          </Typography>
          <div style={{ display: "flex", gap: 8 }}>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={() => onView(shipment)}
            >
              <FontAwesomeIcon icon={faEye} />
            </ActionButton>
            <ActionButton
              variant="outlined"
              color="secondary"
              onClick={() => onDelete(shipment._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ActionButton>
          </div>
        </MobileShipmentCard>
      ))}
    </MobileShipmentList>
  </>
);

export default ShipmentTable;
