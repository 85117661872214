import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAuth } from "./AuthContext/AuthContext";
import {
  ShipmentFormContainer,
  FormWrapper,
  Title,
  StyledButton,
} from "./ShipmentStyles";
import axiosInstance from "./axiosInstance";
import fetchAddressDetails from "./fetchAddressDetails";
import calculateTotalMiles from "./calculateTotalMiles";
import ShipmentFormFields from "./ShipmentFormFields";

const initialState = {
  weight: "",
  description: "",
  priceRange: "",
  pickupTime: "",
  dropOffTime: "",
  totalMiles: 0,
  totalCost: 0,
  maxNegotiationPrice: "",
  origin: { address: "", street: "", city: "", state: "", zipCode: "" },
  destination: { address: "", street: "", city: "", state: "", zipCode: "" },
  isRoundTrip: false,
  departureDate: "",
  costPerMile: 3,
  truckType: "",
  truckSize: "",
  shipmentNumber: "",
  shipmentQuantity: "",
};

// Improved address parsing function
const parseAddressComponents = (components) => {
  const addressData = { street: "", city: "", state: "", zipCode: "" };

  components.forEach((component) => {
    const { types, long_name, short_name } = component;

    // Logging each component for debugging
    console.log(
      `Component type: ${types[0]}, long_name: ${long_name}, short_name: ${short_name}`
    );

    if (types.includes("street_number")) {
      addressData.street = `${long_name} `;
    }
    if (types.includes("route")) {
      addressData.street += long_name; // Append route to street
    }
    if (types.includes("locality") || types.includes("sublocality")) {
      addressData.city = long_name;
    }
    if (types.includes("administrative_area_level_1")) {
      addressData.state = short_name;
    }
    if (types.includes("postal_code")) {
      addressData.zipCode = long_name;
    }
  });

  // Log to check parsed address data before returning
  console.log("Final Parsed Address Data:", addressData);

  return addressData;
};

const PostShipment = ({ onShipmentSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();
  const [formState, setFormState] = useState(initialState);

  const handleFetchDistanceAndCost = useCallback(async () => {
    const { origin, destination, isRoundTrip } = formState;

    if (origin.address && destination.address) {
      try {
        const [originDetails, destinationDetails] = await Promise.all([
          fetchAddressDetails(origin.address),
          fetchAddressDetails(destination.address),
        ]);

        // Log the entire response from fetchAddressDetails
        console.log("Origin Details from fetchAddressDetails:", originDetails);
        console.log(
          "Destination Details from fetchAddressDetails:",
          destinationDetails
        );

        if (originDetails && destinationDetails) {
          const parsedOrigin = parseAddressComponents(
            originDetails.address_components
          );
          const parsedDestination = parseAddressComponents(
            destinationDetails.address_components
          );

          console.log("Parsed Origin Address:", parsedOrigin);
          console.log("Parsed Destination Address:", parsedDestination);

          const totalMiles = calculateTotalMiles(
            originDetails,
            destinationDetails,
            isRoundTrip
          );

          setFormState((prev) => ({
            ...prev,
            origin: { ...prev.origin, ...parsedOrigin },
            destination: { ...prev.destination, ...parsedDestination },
            totalMiles,
            totalCost: (totalMiles * prev.costPerMile).toFixed(2),
          }));
        } else {
          enqueueSnackbar("Failed to fetch address details.", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        enqueueSnackbar("Failed to calculate distance and cost.", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        "Complete address details are required for origin and destination.",
        {
          variant: "error",
        }
      );
    }
  }, [formState, enqueueSnackbar]);

  useEffect(() => {
    handleFetchDistanceAndCost();
  }, [handleFetchDistanceAndCost]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Attempting to submit form with data:", formState);

    if (!company || !company.id) {
      enqueueSnackbar("Company information is missing. Please log in again.", {
        variant: "error",
      });
      return;
    }

    const { origin, destination } = formState;
    console.log("Origin details before validation:", origin);
    console.log("Destination details before validation:", destination);

    if (
      !origin.street ||
      !origin.city ||
      !origin.state ||
      !origin.zipCode ||
      !destination.street ||
      !destination.city ||
      !destination.state ||
      !destination.zipCode
    ) {
      console.log(
        "Validation Error: Complete address details required for origin and destination."
      );
      enqueueSnackbar(
        "Complete address details are required for origin and destination.",
        {
          variant: "error",
        }
      );
      return;
    }

    const shipmentData = {
      ...formState,
      weight: parseFloat(formState.weight),
      companyId: company.id,
    };

    try {
      const response = await axiosInstance.post("/shipment/new", shipmentData);
      console.log("Shipment created successfully:", response.data);
      enqueueSnackbar("Shipment posted successfully", { variant: "success" });
      setFormState(initialState);
      if (onShipmentSubmit) onShipmentSubmit(response.data);
    } catch (error) {
      console.error("Error posting shipment:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to post shipment. Please try again.";
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  return (
    <ShipmentFormContainer>
      <FormWrapper>
        <Title variant="h5">Post Shipment</Title>
        <form onSubmit={handleSubmit}>
          <ShipmentFormFields
            formState={formState}
            setFormState={setFormState}
          />
          <StyledButton type="submit">Post Shipment</StyledButton>
        </form>
      </FormWrapper>
    </ShipmentFormContainer>
  );
};

export default PostShipment;
